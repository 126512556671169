import Button from "core/components/new/Button"
import IconButton from "core/components/IconButton"
import Input from "core/components/new/Input"
import StandaredSelect from "core/components/StandaredSelect"
import { STR_DATE_AND_TIME_FIELD, STR_TEXT_FIELD } from "core/constants/strings"
import React from "react"
import Toast from "core/components/Toast"
import useUpdateClassFormViewModel from "./UpdateClassFormViewModel"
import { format } from "date-fns"
import { AddIcon, CrossIcon } from "core/constants/svgs"
import ConditionalLoader from "core/components/ConditionalLoader"
import { isEmpty, removeAtIndex } from "core/utils/misc"

export default function UpdateClassForm(props: any) {
  const {
    reloadClass,
    setReloadClass,
    selectBatch,
    currentSelectAssignmentsQuestions,
    currentSelectBatch,
    setcurrentSelectBatch,
    chapterNames,
    setChapterNames,
    classLink,
    setClassLink,
    selectInstructor,
    setSelectInstructor,
    selectModuleId,
    setSelectModuleId,
    notes_link,
    setNotesLink,
    selectAssigmentsQuestions,
    setSelectAssigmentsQuestions,
    selectHomeworkQuestions,
    setSelectHomeworkQuestions,
    currentSelectHomeworkQuestions,
    selectPracticalQuestions,
    setSelectPracticalQuestions,
    currentSelectPracticalQuestions,
    selectWarmupQuestions,
    setselectWarmupQuestions,
    currentSelectWarmupQuestions,
    session_timestamp,
    setSessionTimestamp,
    selectTrack,
    setSelectTrack,
    videoId,
    setVideoId,
    notesSummaryLink,
    setNotesSummaryLink,
    videoSecret,
    setVideoSecret,
    loading,
    toast,
    compensationStatus,
    getAllTracks,
    moduleId,
    TrackId,
    othersClassName,
    moduleClassesList,
    selectClassName,
    enableOthersClass,
    sessionDuration,
    otherSessionDuration,
    masterClassType,
    setMasterClassType,
    handleMasterClassType,
    handleNewDuration,
    handleSessionDuration,
    handleOtherSessionDuration,
    setSelectClassName,
    // checkAllClassForModule,
    getModuleClasses,
    getModuleBasedTrack,
    setCompensationStatus,
    handleOthersClass,
    handleCompensationStatus,
    handleRemoveBatch,
    setSessionId,
    setSelectBatch,
    handleAssignmentChange,
    handleAddAssignmentQuestions,
    handleSelectHomeworkChange,
    handleAddHomeworkQuestions,
    handlePracticalChange,
    handleAddPracticalQuestions,
    handleWarmupChange,
    handleBatchChange,
    handleChangeChapterName,
    handleChangeClassLink,
    handleChangeInstructor,
    handleChangeModuleId,
    handleChangeSessionTimestamp,
    handleChangeTrack,
    handleChangeVideoId,
    handleChangeVideoSecreat,
    handleChangeNotesLink,
    handleChangeNotesSummaryLink,
    assignmentSolutionActivationTime,
    setAssignmentSolutionActivationTime,
    assignmentSolutionLink,
    handleChangeAssignmentSolutionActivationTime,
    handleChangeAssignmentSolutionLink,
    setAssignmentSolutionLink,
    removeAssignmentQuestion,
    handleAddWarmupQuestions,
    updateClass,
    setPreviousTimestamp,
    changeToastVisibility,
    assignmentCoins,
    setAssignmentCoins,
    handleChangeAssignmentCoins,
    handleStatusChange,
    status,
    setStatus,
  } = useUpdateClassFormViewModel()

  React.useEffect(() => {
    setcurrentSelectBatch(props.currentSelectBatch)
    setSelectBatch(props.currentSelectBatch)
    // setChapterNames(props.chapterNames);
    setSelectClassName(props.chapterNames)
    setClassLink(props.classLink)
    setSelectInstructor(props.selectInstructor)
    getModuleBasedTrack(props.selectTrack, 0)
    getModuleClasses(props.selectModuleId, props.chapterNames)
    setSelectModuleId(props.selectModuleId)
    setNotesLink(props.notes_link)
    setSelectAssigmentsQuestions(props.selectAssigmentsQuestions)
    setSelectHomeworkQuestions(props.selectHomeworkQuestions)
    setSelectPracticalQuestions(props.selectPracticalQuestions)
    setselectWarmupQuestions(props.selectWarmupQuestions)
    setMasterClassType(props.master_class_type)
    setSessionTimestamp(format(props.session_timestamp * 1000, "yyyy-MM-dd'T'HH:mm"))
    handleNewDuration(props.session_duration.toString(), DurationArray)
    setSelectTrack(props.selectTrack)
    setVideoId(props.videoId)
    setVideoSecret(props.videoSecret)
    setNotesSummaryLink(props.notesSummaryLink)
    setSessionId(props.session_id)
    setPreviousTimestamp(props.session_timestamp)
    setAssignmentSolutionLink(props.assignmentSolutionLink)
    setAssignmentSolutionActivationTime(props.assignmentSolutionActivationTime)
    setCompensationStatus(props.compensation_status ?? "Hourly Class")
    getAllTracks()
    setAssignmentCoins(props.assignmentCoins)
    setStatus(props.status)
  }, [
    props.currentSelectBatch,
    props.chapterNames,
    props.classLink,
    props.selectInstructor,
    props.selectModuleId,
    props.notes_link,
    props.selectAssigmentsQuestions,
    props.selectHomeworkQuestions,
    props.selectPracticalQuestions,
    props.selectWarmupQuestions,
    props.session_timestamp,
    props.selectTrack,
    props.videoId,
    props.videoSecret,
    props.notesSummaryLink,
    props.session_id,
    props.assignmentCoins,
  ])

  React.useEffect(() => {}, [])

  React.useEffect(() => {
    if (reloadClass == true) {
      props.setUpcomingClassBool(!props.upcomingClassBool)
      setReloadClass(!reloadClass)
    }
  }, [reloadClass])
  const compensationStatusArray = ["Hourly Class", "Module Based Class"]
  const masterClassTypeArray = ["Software Engineering", "Data Scientist", "Both"]
  const DurationArray = [1, 1.5, 2, 2.5, 3, "other"]

  const STATUSARRAY: string[] = [
    "Scheduled Class",
    "Class Taken",
    "Cancelled On Time",
    "Substituted Class",
    "Cancelled Post Time",
    "Cancelled Last Min",
  ]

  return (
    <div className="space-y-4">
      <p className="text-xl font-medium">Edit - {props.chapterNames}</p>
      <ul className="flex w-full flex-col border p-2">
        {selectBatch?.map((batch: any) => (
          <li key={batch} className="flex items-center gap-1 p-0.5">
            {batch}
            <button
              className="rounded-sm bg-new-solid-black text-new-solid-white"
              onClick={() => handleRemoveBatch(batch)}
            >
              <CrossIcon className="h-4 w-4" />
            </button>
          </li>
        ))}
        {selectBatch?.length === 0 && <li className="flex items-center gap-1 p-0.5">No Batch Selected</li>}
      </ul>
      <StandaredSelect
        required
        options={props.batch}
        placeholder="Select Batch"
        onChange={handleBatchChange}
        value={currentSelectBatch}
      />
      <StandaredSelect
        required
        options={TrackId}
        placeholder="Track"
        onChange={handleChangeTrack}
        value={selectTrack}
      />
      {selectTrack === "master_class" && (
        <StandaredSelect
          required
          options={masterClassTypeArray}
          placeholder="Master Class Type"
          onChange={handleMasterClassType}
          value={masterClassType}
        />
      )}
      <StandaredSelect
        required
        options={moduleId}
        placeholder="Module ID"
        onChange={handleChangeModuleId}
        value={selectModuleId}
      />
      <select
        className="w-full cursor-pointer appearance-none rounded-sm border border-new-neutral bg-new-solid-white px-4 py-2 text-sm leading-[17px] text-new-neutral outline-none focus:border-new-accent focus:ring-1 focus:ring-new-accent"
        onChange={handleChangeChapterName}
        value={selectClassName}
      >
        {!selectClassName && (
          <option value="" className="bg-neutral-50 text-neutral-500">
            Class Name
          </option>
        )}
        {moduleClassesList?.map((header: any) => (
          <option className="bg-neutral-50 text-neutral-500" value={header.topic}>
            {header.topic}
          </option>
        ))}
        <option value="others">others</option>
      </select>
      {enableOthersClass && (
        <Input type={STR_TEXT_FIELD} value={othersClassName} onChange={handleOthersClass} placeholder="Class Name" />
      )}
      <Input type={STR_TEXT_FIELD} value={classLink} onChange={handleChangeClassLink} placeholder="Class Link" />
      <StandaredSelect
        required
        options={props.instructor?.map((c: any, index: any) => c.email)}
        placeholder="Instructor"
        onChange={handleChangeInstructor}
        value={selectInstructor}
      />
      <StandaredSelect
        required
        options={compensationStatusArray}
        value={compensationStatus}
        onChange={handleCompensationStatus}
        placeholder="Compensation Status"
      />
      <ul className=" flex w-full flex-col border p-2">
        {selectAssigmentsQuestions?.map((assigment: any, index: any) => (
          <li key={index}>
            {assigment}{" "}
            <IconButton
              className="m-2 inline-flex h-[20px] w-[20px] items-center justify-center rounded-full bg-black text-xs text-white"
              onClick={() => removeAssignmentQuestion(index)}
              text="X"
            >
              X
            </IconButton>
          </li>
        ))}
        {selectAssigmentsQuestions?.length === 0 && (
          <li className="flex items-center gap-1 p-0.5">No Assignment Questions Selected</li>
        )}
      </ul>
      <form className=" flex w-full justify-between gap-4" onSubmit={handleAddAssignmentQuestions}>
        <Input
          type={STR_TEXT_FIELD}
          placeholder="Assignments"
          onChange={handleAssignmentChange}
          value={currentSelectAssignmentsQuestions}
          containerClass="w-full"
        />
        <Button small className="ml-auto w-fit">
          <AddIcon className="h-5 w-5 text-new-solid-white" />
        </Button>
      </form>
      <ul className="flex w-full flex-wrap gap-3 border p-2">
        <ConditionalLoader
          isLoading={isEmpty(selectHomeworkQuestions)}
          loader={<em className="text-xs text-new-neutral-light">No homework question added</em>}
        >
          {selectHomeworkQuestions?.map((homework: any, i: number) => (
            <li className="inline-flex items-center gap-2">
              {homework}
              <button
                className="rounded bg-new-solid-black p-1 text-new-solid-white"
                onClick={() => setSelectHomeworkQuestions(removeAtIndex(selectHomeworkQuestions, i))}
              >
                <CrossIcon className="h-4 w-4" />
              </button>
            </li>
          ))}
        </ConditionalLoader>
      </ul>
      <form className="flex w-full justify-between gap-4" onSubmit={handleAddHomeworkQuestions}>
        <Input
          type={STR_TEXT_FIELD}
          placeholder="Homework"
          onChange={handleSelectHomeworkChange}
          value={currentSelectHomeworkQuestions}
          containerClass="w-full"
        />
        <Button small className="ml-auto w-fit">
          <AddIcon className="h-5 w-5 text-new-solid-white" />
        </Button>
      </form>
      <ul className="flex w-full flex-wrap gap-3 border p-2">
        <ConditionalLoader
          isLoading={isEmpty(selectPracticalQuestions)}
          loader={<em className="text-xs text-new-neutral-light">No practice question added</em>}
        >
          {selectPracticalQuestions?.map((practical: any, i: number) => (
            <li className="inline-flex items-center gap-2">
              {practical}
              <button
                className="rounded bg-new-solid-black p-1 text-new-solid-white"
                onClick={() => setSelectPracticalQuestions(removeAtIndex(selectPracticalQuestions, i))}
              >
                <CrossIcon className="h-4 w-4" />
              </button>
            </li>
          ))}
        </ConditionalLoader>
      </ul>
      <form className="flex w-full justify-between gap-4" onSubmit={handleAddPracticalQuestions}>
        <Input
          type={STR_TEXT_FIELD}
          placeholder="Practice Questions"
          onChange={handlePracticalChange}
          value={currentSelectPracticalQuestions}
          containerClass="w-full"
        />
        <Button small className="ml-auto w-fit">
          <AddIcon className="h-5 w-5 text-new-solid-white" />
        </Button>
      </form>
      <ul className="flex w-full flex-wrap gap-3 border p-2">
        <ConditionalLoader
          isLoading={isEmpty(selectWarmupQuestions)}
          loader={<em className="text-xs text-new-neutral-light">No warmup question added</em>}
        >
          {selectWarmupQuestions?.map((questions: any, i: number) => (
            <li className="inline-flex items-center gap-2">
              {questions}
              <button
                className="rounded bg-new-solid-black px-1 py-1 text-new-solid-white"
                onClick={() => setselectWarmupQuestions(removeAtIndex(selectWarmupQuestions, i))}
              >
                <CrossIcon className="h-4 w-4" />
              </button>
            </li>
          ))}
        </ConditionalLoader>
      </ul>
      <form className="flex w-full justify-between gap-4" onSubmit={handleAddWarmupQuestions}>
        <Input
          type={STR_TEXT_FIELD}
          placeholder="Warmup Questions"
          onChange={handleWarmupChange}
          value={currentSelectWarmupQuestions}
          containerClass="w-full"
        />
        <Button small className="ml-auto w-fit">
          <AddIcon className="h-5 w-5 text-new-solid-white" />
        </Button>
      </form>
      <Input
        type={STR_DATE_AND_TIME_FIELD}
        value={session_timestamp}
        onChange={handleChangeSessionTimestamp}
        placeholder="Date && Time"
      />
      <StandaredSelect
        required
        options={DurationArray}
        value={sessionDuration}
        onChange={handleSessionDuration}
        placeholder="Session Duration (in hrs)"
      />
      {sessionDuration == "other" && (
        <Input
          required
          type="number"
          value={otherSessionDuration}
          onChange={handleOtherSessionDuration}
          placeholder="Session Duration (in hrs)"
        />
      )}
      <Input type={STR_TEXT_FIELD} value={videoId} onChange={handleChangeVideoId} placeholder="Video ID" />
      <Input type={STR_TEXT_FIELD} value={videoSecret} onChange={handleChangeVideoSecreat} placeholder="Video Secret" />
      <Input type={STR_TEXT_FIELD} value={notes_link} onChange={handleChangeNotesLink} placeholder="Notes Link" />
      <Input
        type={STR_TEXT_FIELD}
        value={notesSummaryLink}
        onChange={handleChangeNotesSummaryLink}
        placeholder="Notes Summary Link"
      />
      <Input
        type={STR_TEXT_FIELD}
        value={assignmentSolutionLink}
        onChange={handleChangeAssignmentSolutionLink}
        placeholder="Assignment Solution Link"
      />
      <Input
        type="number"
        max="100"
        min="0"
        placeholder="Assignment Solution Activation Time (in Days)"
        value={assignmentSolutionActivationTime}
        onChange={handleChangeAssignmentSolutionActivationTime}
      />
      <StandaredSelect
        required
        options={STATUSARRAY}
        value={status}
        onChange={handleStatusChange}
        placeholder="Status"
      />
      <Input
        type="number"
        max="100"
        min="0"
        placeholder="Assignment Coins"
        value={assignmentCoins}
        onChange={handleChangeAssignmentCoins}
      />
      <Button onClick={updateClass}>{loading ? "Updating..." : "Update Class"}</Button>
      <Toast data={toast} onClick={() => changeToastVisibility(false)} />
    </div>
  )
}
