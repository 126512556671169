import React from "react"
import Button from "core/components/Button"
import Input from "core/components/new/Input"
import DashboardLayout from "core/layouts/DashboardLayout"
import useMentorProfileViewModel from "./MentorProfilePageViewModel"
import MenteesTable from "./components/MenteesTable"
import AvailibilityTable from "./components/AvailibilityTable"
import { format } from "date-fns"
import Toast from "core/components/Toast"
import ConfirmationPopUp from "./components/ConfirmationPopUp"
import { DropDown } from "assets/svgs/Dropdown"
import { Textarea } from "core/components/v2/Textarea"
import Spinner from "core/components/Spinner"

export default function MentorProfilePageView() {
  const {
    searchEmail,
    mentees,
    loading,
    mentor,
    comments,
    availability,
    comment,
    isAlumni,
    isMentor,
    courseType,
    toast,
    selectedFile,
    addComment,
    onChangeComment,
    GetMentorProfile,
    onChangeSearchEmail,
    onChangeMentorObj,
    setSelectedFile,
    updateMentorProfile,
    updateMenteesMentor,
    changeToastVisibility,
    handleCourseTypeChange,
    handleMentorChange,
    handleAlumniChange,
    handleProfilePictureUpload,
    showDetails,
    handleShowDetails,
    upDateMentorsMentees,
    textArea,
    setTextArea,
    setTechStack,
    techStack,
  } = useMentorProfileViewModel()

  const [isOpen, setIsOpen] = React.useState<any>(false)

  React.useEffect(() => {
    const email = window.location.pathname.split("/")[4]
    GetMentorProfile(email)
  }, [])

  return (
    <DashboardLayout>
      {isOpen && (
        <ConfirmationPopUp
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          email={mentor.email}
          updateMenteesMentor={updateMenteesMentor}
          heading="Are you sure you want to remove all the mentees?"
        />
      )}
      <Toast data={toast} onClick={() => changeToastVisibility(false)} />
      {loading ? (
        <div className="flex justify-center align-middle items-center">
          <Spinner></Spinner>
        </div>
      ) : (
        <div className="px-12 py-10">
          <div>
            <div className="rounded-[2px] border-[0.4px] border-[#ADADAD] p-2 mt-2 cursor-pointer">
              <div
                className="flex flex-row justify-between items-center p-2 cursor-pointer"
                onClick={() => handleShowDetails("basicInformation")}
              >
                <div className="text-[20px] font-semibold">Basic Information</div>
                <button className={showDetails !== "basicInformation" ? "rotate-180" : ""}>
                  <DropDown />
                </button>
              </div>
              {showDetails === "basicInformation" && (
                <div
                  className={`grid grid-cols-4 gap-4 p-4 transition-all duration-700 ease-in-out transform ${
                    showDetails === "basicInformation" ? "opacity-100 max-h-full" : "opacity-0 max-h-0"
                  } overflow-hidden`}
                >
                  <Input placeholder="Email" value={mentor.email} onChange={onChangeMentorObj} id="email" />
                  <Input placeholder="Bandwidth" value={mentor.bandwidth} onChange={onChangeMentorObj} id="bandwidth" />
                  <Input placeholder="Name" value={mentor?.name} onChange={onChangeMentorObj} id="name" />
                  <Input placeholder="Phone" value={mentor?.phone} onChange={onChangeMentorObj} id="phone" />
                  <Input
                    placeholder="Current company"
                    value={mentor?.current_company}
                    onChange={onChangeMentorObj}
                    id="current_company"
                  />
                  <Input
                    placeholder="Experience"
                    value={mentor?.experience}
                    onChange={onChangeMentorObj}
                    id="experience"
                  />
                  <Input
                    placeholder="Linkedin profile"
                    value={mentor?.linkedin_profile}
                    onChange={onChangeMentorObj}
                    id="linkedin_profile"
                    containerClass="col-span-2"
                  />
                  <Input
                    type="file"
                    onChange={handleProfilePictureUpload}
                    id="mentor_profile_image"
                    // containerClass="col-span-2"
                    className="file:bg-none file:p-0 file:border-none"
                  />
                  <Input placeholder="Password" value={mentor?.password} onChange={onChangeMentorObj} id="password" />
                  <Input
                    type="text"
                    onChange={handleProfilePictureUpload}
                    value={mentor?.photo}
                    containerClass="col-span-2"
                  />

                  <div className="mb-4 mt-4 flex flex-col py-1 px-2 text-[20px] font-semibold">
                    <ul className="ml-4 list-disc">
                      <li>Other Details</li>
                    </ul>
                    <div className="flex gap-x-10 px-4 py-2">
                      <div className="flex flex-col">
                        <h4 className="text-sm">Alumni :</h4>
                        <select value={isAlumni ? "True" : "False"} onChange={handleAlumniChange} id="is_alumni">
                          <option value="" disabled>
                            is Alumni
                          </option>
                          <option value="True">True</option>
                          <option value="False">False</option>
                        </select>
                      </div>

                      <div className="flex flex-col">
                        <h4 className="text-sm">Mentor :</h4>
                        <select value={isMentor ? "True" : "False"} onChange={handleMentorChange} id="is_mentor">
                          <option value="" disabled>
                            is Mentor
                          </option>
                          <option value="True">True</option>
                          <option value="False">False</option>
                        </select>
                      </div>

                      <div className="flex flex-col">
                        <h4 className="text-sm">Course Type :</h4>
                        <select value={courseType} onChange={handleCourseTypeChange} id="course_type">
                          <option value="" disabled>
                            Course Type
                          </option>
                          <option value="SDE">SDE</option>
                          <option value="DSML">DSML</option>
                          <option value="Both">Both</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="rounded-[2px] border-[0.4px] border-[#ADADAD] p-2 mt-4 cursor-pointer">
            <div
              className="flex flex-row justify-between items-center p-2 cursor-pointer"
              onClick={() => handleShowDetails("invoicingDetails")}
            >
              <div className="text-[20px] font-semibold">Invoicing Details</div>
              <button className={showDetails !== "invoicingDetails" ? "rotate-180" : ""}>
                <DropDown />
              </button>
            </div>
            {showDetails === "invoicingDetails" && (
              <div
                className={`flex flex-wrap gap-4 p-4 transition-all duration-700 ease-in-out transform ${
                  showDetails === "invoicingDetails" ? "opacity-100 h-auto" : "opacity-0 h-0"
                } overflow-hidden`}
              >
                <Textarea
                  placeholder="Address"
                  value={mentor?.address}
                  id="address"
                  onChange={(e: any) => onChangeMentorObj(e)}
                />
                <Input
                  placeholder="Beneficiary Name"
                  value={mentor?.beneficiary_name}
                  onChange={onChangeMentorObj}
                  id="beneficiary_name"
                />
                <Input placeholder="IFSC Code" value={mentor?.IFSC} onChange={onChangeMentorObj} id="IFSC" />
                <Input
                  placeholder="Account Number"
                  value={mentor?.account_number}
                  onChange={onChangeMentorObj}
                  id="account_number"
                  containerClass="flex-1"
                />
              </div>
            )}
          </div>
          <div className="mt-25 flex flex-col gap-4 mt-4">
            {mentees && mentees.length > 0 && (
              <div className="flex-0.5  border-[0.4px] rounded-[2px] border-[#ADADAD] px-4 cursor-pointer">
                <div
                  className="flex flex-row justify-between items-center "
                  onClick={() => handleShowDetails("mentees")}
                >
                  <div className="mb-4 mt-4 text-[20px] font-semibold">Mentees</div>
                  <button className={showDetails !== "mentees" ? "rotate-180" : ""}>
                    <DropDown />
                  </button>
                </div>
                <div className="p-1">
                  {showDetails === "mentees" && (
                    <MenteesTable upDateMentorsMentee={upDateMentorsMentees} mentees={mentees} />
                  )}
                </div>
              </div>
            )}

            {availability && Object.values(availability).some((arr) => Array.isArray(arr) && arr.length > 0) && (
              <div className="flex-1 border-[0.4px] rounded-[2px] border-[#ADADAD] px-4 cursor-pointer">
                <div
                  className="flex flex-row justify-between items-center"
                  onClick={() => handleShowDetails("availability")}
                >
                  <div className="mb-4 mt-4 text-[20px] font-semibold">Availability</div>
                  <button className={showDetails !== "availability" ? "rotate-180" : ""}>
                    <DropDown />
                  </button>
                </div>
                <div className="p-1">
                  {showDetails === "availability" && <AvailibilityTable availability={availability} />}
                </div>
              </div>
            )}
          </div>

          <div className="rounded-[2px] border-[0.4px] border-[#ADADAD] p-2 mt-4 cursor-pointer">
            <div
              className="flex flex-row justify-between items-center p-2 cursor-pointer"
              onClick={() => handleShowDetails("comments")}
            >
              <div className="text-[20px] font-semibold">Comments</div>
              <button className={showDetails !== "comments" ? "rotate-180" : ""}>
                <DropDown />
              </button>
            </div>
            {showDetails === "comments" && (
              <div
                className={`grid grid-cols-4 gap-4 p-4 transition-all duration-700 ease-in-out transform ${
                  showDetails === "comments" ? "opacity-100 max-h-full" : "opacity-0 max-h-0"
                } overflow-hidden`}
              >
                {comments?.map((comment: any) => (
                  <div className="rounded-lg border-2 border-solid p-4" key={comment.id}>
                    {format(new Date(comment?.date), "MMM dd / yyyy @ hh:mm a")}
                    <p className="text-bold font-semibold text-new-accent"> {comment.comment} </p>
                  </div>
                ))}
                <Input id="comment" placeholder="Add comment" value={comment} onChange={onChangeComment} />
                <Button className="h-10 w-20" onClick={addComment}>
                  Add
                </Button>
              </div>
            )}
          </div>

          <div className="rounded-[2px] border-[0.4px] border-[#ADADAD] p-2 mt-4 cursor-pointer">
            <div
              className="flex flex-row justify-between items-center p-2 cursor-pointer"
              onClick={() => handleShowDetails("removeAllMentees")}
            >
              <div className="text-[20px] font-semibold">Remove All Mentees</div>
              <button className={showDetails !== "removeAllMentees" ? "rotate-180" : ""}>
                <DropDown />
              </button>
            </div>
            {showDetails === "removeAllMentees" && (
              <div
                className={`grid grid-cols-4 gap-4 p-4 transition-all duration-700 ease-in-out transform ${
                  showDetails === "removeAllMentees" ? "opacity-100 max-h-full" : "opacity-0 max-h-0"
                } overflow-hidden`}
              >
                <div className="">
                  <Button className="w-25 h-10" onClick={() => setIsOpen(true)}>
                    Remove
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "40px",
        }}
      >
        <div className="flex w-40 flex-col items-center">
          <Button className="bg-new-accent text-white rounded border" onClick={updateMentorProfile}>
            Submit
          </Button>
        </div>
      </div>
    </DashboardLayout>
  )
}
