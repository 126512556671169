import React, { useState } from "react"
import { MentorDashboardAPIDataSourceImpl } from "data/API/Admin/MentorDashboardAPIDataSourceImpl"
import { MentorDashboardRepositoryImpl } from "data/repository/Admin/MentorDashboardRepositoryImpl"
import { useAuth } from "core/context/auth"
import { GetMentorprofile } from "domain/useCase/Admin/MentorDashboard/GetMentorProfile"
import { UpdateMentorProfile } from "domain/useCase/Admin/MentorDashboard/UpdateMentorProfile"
import useToast from "core/hooks/useToast"
import { STR_FAILURE, STR_MENTOR_INFO_SAVED, STR_SUCCESS } from "core/constants/strings"
import { UpdateMenteesMentor } from "domain/useCase/Admin/MentorDashboard/UpdateMenteesMentor"
import { UploadMentorImage } from "domain/useCase/Admin/MentorDashboard/UploadMentorImage"
import { DeleteMentorImage } from "domain/useCase/Admin/MentorDashboard/DeleteMentorImage"
import { DeleteMentee } from "domain/useCase/Admin/MentorDashboard/DeleteMentee"
import { resolveModuleName } from "typescript"

export default function MentorProfilePageViewModel() {
  const [comments, setComments] = useState<any>([])
  const [mentor, setMentor] = useState<any>({})
  const [mentees, setMentees] = useState<any>([])
  const [loading, setLoading] = useState<Boolean>(true)
  const [availability, setAvailability] = useState<any>({
    sunday: [],
    monday: [],
    tuesday: [],
    wednesday: [],
    thursday: [],
    friday: [],
    saturday: [],
  })
  const [showDetails, setShowDetails] = useState<string>("")
  const [searchEmail, setSearchEmail] = useState<any>("")
  const [password, setPassword] = useState<any>("")
  const [comment, setComment] = useState<string>("")
  const [isAlumni, setIsAlumni] = useState<boolean>()
  const [isMentor, setIsMentor] = useState<boolean>()
  const [courseType, setCourseType] = useState<string>("")
  const [imgSrc, setImgSrc] = React.useState<any>("")
  const [profilePic, setProfilePic] = React.useState<FormData>(new FormData())
  const { toast, changeToastVisibility, changeToastDetails } = useToast()
  const { auth } = useAuth()
  const [selectedFile, setSelectedFile] = React.useState<File | null>(null)
  const [textArea, setTextArea] = React.useState<string>("")
  const [techStack, setTechStack] = React.useState<string[]>([])
  // const [mentor]

  const GetMentorProfileUseCase = new GetMentorprofile(
    new MentorDashboardRepositoryImpl(new MentorDashboardAPIDataSourceImpl())
  )
  const UpdateMentorProfileUseCase = new UpdateMentorProfile(
    new MentorDashboardRepositoryImpl(new MentorDashboardAPIDataSourceImpl())
  )
  const UpdateMenteesMentorUseCase = new UpdateMenteesMentor(
    new MentorDashboardRepositoryImpl(new MentorDashboardAPIDataSourceImpl())
  )
  const UploadMentorImageUseCase = new UploadMentorImage(
    new MentorDashboardRepositoryImpl(new MentorDashboardAPIDataSourceImpl())
  )
  const DeleteMentorImageUseCase = new DeleteMentorImage(
    new MentorDashboardRepositoryImpl(new MentorDashboardAPIDataSourceImpl())
  )
  const DeleteMenteeUseCase = new DeleteMentee(
    new MentorDashboardRepositoryImpl(new MentorDashboardAPIDataSourceImpl())
  )

  async function GetMentorProfile(id: any) {
    const response = await GetMentorProfileUseCase.invoke({
      admin_uid: auth.local_id,
      email: id,
    })
    if (response) {
      setIsAlumni(response.response.mentor.is_alumni)
      setIsMentor(response.response.mentor.is_mentor)
      setCourseType(response.response.mentor.course_type)
      setMentor(response.response.mentor)
      setTechStack(response.response.mentor?.tech_stack || [])
      setSearchEmail(response.response.mentor.email)
      setMentees(response.response.mentor.mentees)
      setAvailability(response.response.mentor.availability)
      setComments(response.response.mentor.comments === undefined ? [] : response.response.mentor.comments)
      setSelectedFile(response.response.mentor.photo)
      setPassword(response.response.mentor.password)
    }
    setLoading(false)
    return response
  }

  async function updateMentorProfile() {
    try {
      const file = profilePic.get("img") as File
      let updatedMentor = { ...mentor }
      if (techStack.length > 0) updatedMentor = { ...updatedMentor, tech_stack: techStack }
      if (file) {
        // Delete existing photo if any
        if (mentor.photo) {
          const deleteResponse = await DeleteMentorImageUseCase.invoke(auth, mentor.photo)

          if (!deleteResponse.success) {
            console.error("Error deleting mentor photo:", deleteResponse.error)
            changeToastVisibility(true)
            changeToastDetails(STR_FAILURE, "Error deleting mentor photo")
            return
          }
        }

        // Upload the mentor image
        const uploadResponse = await UploadMentorImageUseCase.invoke(auth, file)

        if (!uploadResponse?.success) {
          changeToastDetails(STR_FAILURE, "Unknown error occurred!")
          changeToastVisibility(true)
          return
        }

        const urlResponse = uploadResponse.data
        updatedMentor = { ...mentor, photo: urlResponse }
        setMentor(updatedMentor)
      }

      const updateProfileResponse = await UpdateMentorProfileUseCase.invoke({
        mentor: updatedMentor,
      })

      if (updateProfileResponse.success) {
        changeToastVisibility(true)
        changeToastDetails(STR_SUCCESS, STR_MENTOR_INFO_SAVED)
      } else {
        changeToastVisibility(true)
        changeToastDetails(STR_FAILURE, updateProfileResponse.error)
      }
    } catch (error) {
      console.error("Error updating mentor profile:", error)
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, "Error updating mentor profile")
    }
  }

  async function upDateMentorsMentees(email: string) {
    const response = await DeleteMenteeUseCase.invoke(email, mentor?.email)
    if (response.success) {
      changeToastVisibility(true)
      changeToastDetails(STR_SUCCESS, "Mentee delete sucessfully")
    } else {
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, response.error)
    }
  }

  async function updateMenteesMentor(id: any) {
    const response = await UpdateMenteesMentorUseCase.invoke({
      id: id,
    })
    if (response.success) {
      changeToastVisibility(true)
      changeToastDetails(STR_SUCCESS, STR_MENTOR_INFO_SAVED)
    } else {
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, response.error)
    }
  }

  function onChangeComment(e: React.ChangeEvent<HTMLInputElement>) {
    setComment(e.target.value)
  }

  function addComment(e: any) {
    const newComment = {
      date: Date.now(),
      comment: comment,
    }
    setComments([...comments, newComment])
    setComment("")
  }

  function onChangeSearchEmail(e: React.ChangeEvent<HTMLInputElement>) {
    setSearchEmail(e.target.value)
  }

  const onChangeMentorObj = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newMentorObj = { ...mentor }
    newMentorObj[e.target.id] = e.target.value
    setMentor(newMentorObj)
  }

  const handleAlumniChange = (e: any) => {
    const newMentorObj = { ...mentor }
    e.target.value === "True" ? (newMentorObj[e.target.id] = true) : (newMentorObj[e.target.id] = false)

    setMentor(newMentorObj)
    setIsAlumni(e.target.value === "True" ? true : false)
  }

  const handleMentorChange = (e: any) => {
    const newMentorObj = { ...mentor }
    e.target.value === "True" ? (newMentorObj[e.target.id] = true) : (newMentorObj[e.target.id] = false)
    setMentor(newMentorObj)
    setIsMentor(e.target.value === "True" ? true : false)
  }

  const handleCourseTypeChange = (e: any) => {
    const newMentorObj = { ...mentor }
    newMentorObj[e.target.id] = e.target.value
    setMentor(newMentorObj)
    setCourseType(e.target.value)
  }
  const handleShowDetails = (showDetailsOf: string) => {
    showDetails === showDetailsOf ? setShowDetails("") : setShowDetails(showDetailsOf)
  }
  const handleProfilePictureUpload = (e: any) => {
    const reader = new FileReader()
    const file = e.target.files[0]
    setSelectedFile(file)
    reader.readAsDataURL(file)
    reader.onload = (evt) => {
      setImgSrc(evt?.target?.result)
    }
    const form = new FormData()
    form.append("img", file)
    setProfilePic(form)
  }

  return {
    toast,
    searchEmail,
    comments,
    comment,
    mentor,
    mentees,
    availability,
    isAlumni,
    isMentor,
    courseType,
    selectedFile,
    GetMentorProfile,
    onChangeMentorObj,
    onChangeSearchEmail,
    onChangeComment,
    setSelectedFile,
    addComment,
    updateMentorProfile,
    changeToastDetails,
    changeToastVisibility,
    updateMenteesMentor,
    handleCourseTypeChange,
    handleMentorChange,
    handleProfilePictureUpload,
    handleAlumniChange,
    showDetails,
    handleShowDetails,
    loading,
    upDateMentorsMentees,
    textArea,
    setTextArea,
    setTechStack,
    techStack,
  }
}
