import { Auth } from "domain/model/Auth"
import { SessionRepository } from "domain/repository/Mentor/SessionRepository"
import { SessionDataSource } from "data/dataSource/Mentor/SessionDataSource"

export class SessionRepositoryImpl implements SessionRepository {
  private dataSource: SessionDataSource

  constructor(dataSource: SessionDataSource) {
    this.dataSource = dataSource
  }

  async getMentorSessions(auth: Auth) {
    return await this.dataSource.getMentorSessions(auth)
  }

  async cancelSession(auth: Auth, date: string, start: string, end: string) {
    return await this.dataSource.cancelSession(auth, date, start, end)
  }

  async writeStudentFeedback(room_id: string, feedback: string, action_items: string) {
    return await this.dataSource.writeStudentFeedback(room_id, feedback, action_items)
  }

  async fetchMonthlyMentorSession(auth: Auth, start: string, end: string) {
    return await this.dataSource.fetchMonthlyMentorSession(auth, start, end)
  }
  async getAllSessions(auth: Auth, month: number, role: string): Promise<any> {
    return await this.dataSource.getAllSessions(auth, month, role)
  }
  async getInstructorSessions(auth: Auth, month: number, batch: string, module: string): Promise<any> {
    return await this.dataSource.getInstructorSessions(auth, month, batch, module)
  }

  async getMentorSession(auth: Auth, month: number, batch: string, module: string): Promise<any> {
    return await this.dataSource.getMentorSession(auth, month, batch, module)
  }

  async getGoalSettingSessions(auth: Auth, mentor_id: string, month: number): Promise<any> {
    return await this.dataSource.getGoalSettingSession(auth, mentor_id, month)
  }

  async getGoalSettingSessionAmountUpdate(auth: Auth, mentor_id: string, amount: number): Promise<any> {
    return await this.dataSource.getGoalSettingSessionAmountUpdate(auth, mentor_id, amount)
  }

  async getExtraGoalSettingSessionAmountUpdate(auth: Auth, mentor_id: string, extra_amount: number): Promise<any> {
    return await this.dataSource.getExtraGoalSettingSessionAmountUpdate(auth, mentor_id, extra_amount)
  }

  async getGoalSettingStatusUpdate(auth: Auth, id: string, status: string): Promise<any> {
    return await this.dataSource.getGoalSettingStatusUpdate(auth, id, status)
  }
}
