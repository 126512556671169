import Button from "core/components/new/Button"
import { useAuth } from "core/context/auth"
import React from "react"
import MentorsViewModel from "../MentorsViewModel"
import { useParams } from "react-router-dom"
import useToast from "core/hooks/useToast"
import Toast from "core/components/Toast"
import { STR_FAILURE, STR_SUCCESS } from "core/constants/strings"
import { MentorSessionsRepositoryImpl } from "data/repository/Admin/MentorSessionsRepositoryImpl"
import MentorSessionsAPIDataSourceImpl from "data/API/Admin/MentorSessionsAPIDataSourceImpl"
import UpdateMentorSession from "domain/useCase/Admin/MentorSessions/UpdateMentorSession"

export default function UpdateSession(props: any) {
  const STATUSARRAY: string[] = [
    "Scheduled Session",
    "Completed",
    "Cancelled by Mentor",
    "Cancelled by Mentee",
    "Taken Elsewhere",
    "Mentor Not Joined",
    "Mentee Not Joined",
  ]

  const { id } = useParams()
  const { auth } = useAuth()
  const { toast, changeToastVisibility, changeToastDetails } = useToast()
  const { actual_duration, considered_duration, status, setStatus, setActualDuration, setConsideredDuration } =
    MentorsViewModel()
  const [loading, setLoading] = React.useState(false)

  React.useEffect(() => {
    setActualDuration(props.data.actual_duration ? props.data.actual_duration : 0)
    setConsideredDuration(props.data.considered_duration ? props.data.considered_duration : 0)
    setStatus(props.data.status ? props.data.status : "")
  }, [id])

  const UpdateMentorSessionUseCase = new UpdateMentorSession(
    new MentorSessionsRepositoryImpl(new MentorSessionsAPIDataSourceImpl())
  )

  function handleActualDuration(e: any) {
    if (e.target.value) setActualDuration(e.target.value)
    else setActualDuration("")
  }
  function handleConsideredDuration(e: any) {
    if (e.target.value) setConsideredDuration(e.target.value)
    else setConsideredDuration("")
  }
  function handleStatus(e: any) {
    if (e.target.value) setStatus(e.target.value)
    else setStatus("")
  }
  const fetchUpdatedStatus = async (session_id: string) => {
    const response = await UpdateMentorSessionUseCase.invoke(
      auth,
      session_id,
      status,
      parseInt(actual_duration),
      parseInt(considered_duration)
    )

    if (!response?.success) {
      changeToastVisibility(true)
      changeToastDetails(STR_FAILURE, response.error)

      return
    }
    changeToastVisibility(true)
    changeToastDetails(STR_SUCCESS, "Mentor Session Updated")
  }
  async function handleSubmit() {
    setLoading(true)
    await fetchUpdatedStatus(props.data.room_id)
    props.setUpdateCount(!props.updateCount)
    setLoading(false)
  }

  return (
    <div className="">
      {" "}
      <div className="space-y-4">
        <div className="flex gap-[32px]">
          <h3 className=" gap-[16px]">{props.data.mentor_id}</h3>
          <div className="flex flex-col gap-2">
            <p className="">{props.data.name}</p>
            <p className="">{props.date}</p>
          </div>
          {props.data?.student_review?.rating && <h3>{props.data.student_review.rating} rating</h3>}
        </div>
        <div className="flex gap-x-4">
          <select value={status} onChange={handleStatus} className="py-1">
            <option value="">Select any status</option>
            {STATUSARRAY.map((item: string, index: number) => (
              <option key={index} value={item}>
                {item}
              </option>
            ))}
          </select>
          <input
            id="Actual Time"
            type="text"
            placeholder="Actual time in minutes"
            value={actual_duration}
            onChange={handleActualDuration}
          />
          <input
            id="Considered time"
            type="text"
            placeholder="Considered time in minutes"
            value={considered_duration}
            onChange={handleConsideredDuration}
          />
        </div>
        <Button loading={loading} outlined className="ml-auto w-fit" onClick={handleSubmit}>
          Submit Changes
        </Button>
      </div>
      <Toast data={toast} onClick={() => changeToastVisibility(false)} />
    </div>
  )
}
