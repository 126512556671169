import DashboardLayout from "core/layouts/DashboardLayout"
import React from "react"
import { useNavigate, useParams } from "react-router-dom"
import GenerateInvoiceViewModel from "./GenerateInvoiceViewModel"
import Disclosure from "./components/Disclosure"
import CompensationTable from "./components/CompensationTable"
import { format } from "date-fns"
import Model from "core/components/Model"
import input from "core/components/new/Input"
import MonthlySessionTable from "./components/MonthlySessionTable"
import { STR_DATE_AND_TIME_FIELD, STR_FAILURE, STR_SUCCESS } from "core/constants/strings"
import useToast from "core/hooks/useToast"
import Toast from "core/components/Toast"
import Button from "core/components/new/Button"
import IncentiveTable from "./components/IncentiveTable"
import StandaredSelect from "core/components/StandaredSelect"
import ModuleClassViewModel from "./ModuleClassViewModel"
import ModuleClassTable from "./components/ModuleClassTable"
import Input from "core/components/Input"
import { isEmpty } from "core/utils/misc"
import Spinner from "core/components/Spinner"
import UpdateInstructorCompensationDetails from "domain/useCase/Admin/GenerateInvoice/UpdateInstructorCompensationDetails"
import { GenerateInvoiceRepositoryImpl } from "data/repository/Admin/GenerateInvoiceRepositoryImpl"
import GenerateInvoiceAPIDataSourceImpl from "data/API/Admin/GenerateInvoiceAPIDataSourceImpl"
import { useAuth } from "core/context/auth"
import { Auth } from "domain/model/Auth"
import { genError } from "core/utils/string"
import UpdateInstructorIncentiveDetails from "domain/useCase/Admin/GenerateInvoice/UpdateInstructorIncentiveDetails"
import { CreateModuleClass } from "domain/model/ModuleClass"
import { CreateModuleClasses } from "domain/useCase/Admin/ModuleClasses/CreateModuleClass"
import ModuleClassAPIDataSourceImpl from "data/API/Admin/ModuleClassAPIDataSourceImpl"
import { ModuleClassRepositoryImpl } from "data/repository/Admin/ModuleClassRepositoryImpl"

export default function InstructorView() {
  const {
    auth,
    yearMonth,
    compensationAmount,
    instructorDetails,
    Loading,
    incentiveLoading,
    instructorIncentiveDetails,
    moduleId,
    getBatchList,
    getBatches,
    getAllModules,
    setCompensationAmount,
    setYearMonth,
    setLoading,
    setIncentiveLoading,
    instructorAverageRating,
    fetchInstructorAverageRating,
    fetchInstructorIncentiveDetails,
    fetchInstructorDetails,
  } = GenerateInvoiceViewModel()

  const {
    createModuleAmount,
    createModuleBatch,
    createModuleEndDate,
    createModuleStartDate,
    createModuleStatus,
    getModuleClasses,
    createModuleName,
    otherModules,
    moduleLoader,
    setCreateModuleName,
    setModuleloader,
    handleOtherModules,
    handleCreateModuleName,
    handleCreateAmount,
    handleCreateBatch,
    handleCreateEndDate,
    handleCreateStartDate,
    handleCreateStatus,
    fetchModuleClasses,
  } = ModuleClassViewModel()
  const { id } = useParams()
  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const [reloadData, setReloadData] = React.useState(false)
  const [incentiveYearMonth, setIncentiveYearMonth] = React.useState<string>("")
  const [incentiveAmount, setIncentiveAmount] = React.useState<string>("")
  const { instructorList, getInstructorsList } = GenerateInvoiceViewModel()
  const { toast, changeToastVisibility, changeToastDetails } = useToast()
  const navigate = useNavigate()
  const updateCompensation = async () => {
    const yearMonth1 = yearMonth.replace(/-/g, "")
    setLoading(true)
    await fetchUpdatedInstructorCompensationDetails(auth, id as string, parseInt(compensationAmount), yearMonth1)
    await fetchInstructorDetails(auth, id as string)
    setLoading(false)
  }
  const UpdateInstructorCompensationDetailsUseCase = new UpdateInstructorCompensationDetails(
    new GenerateInvoiceRepositoryImpl(new GenerateInvoiceAPIDataSourceImpl())
  )
  const UpdateInstructorIncentiveDetailsUseCase = new UpdateInstructorIncentiveDetails(
    new GenerateInvoiceRepositoryImpl(new GenerateInvoiceAPIDataSourceImpl())
  )
  const createModuleClassesUseCase = new CreateModuleClasses(
    new ModuleClassRepositoryImpl(new ModuleClassAPIDataSourceImpl())
  )
  const fetchUpdatedInstructorCompensationDetails = async (
    auth: Auth,
    instructor_id: string,
    compensationAmount: number,
    yearMonth: string
  ) => {
    const response = await UpdateInstructorCompensationDetailsUseCase.invoke(
      auth,
      instructor_id,
      compensationAmount,
      yearMonth
    )

    if (!response?.success) {
      setLoading(false)
      changeToastDetails(STR_FAILURE, genError(response, "Error occurred!"))
      changeToastVisibility(true)
      return
    }
    setLoading(false)
    changeToastDetails(STR_SUCCESS, "Compensation Added!")
    changeToastVisibility(true)
  }

  const fetchUpdatedInstructorIncentiveDetails = async (
    auth: Auth,
    instructor_id: string,
    incentiveAmount: number,
    incentiveYearMonth: string
  ) => {
    const response = await UpdateInstructorIncentiveDetailsUseCase.invoke(
      auth,
      instructor_id,
      incentiveAmount,
      incentiveYearMonth
    )
    if (!response?.success) {
      changeToastDetails(STR_FAILURE, genError(response, "Error occurred!"))
      changeToastVisibility(true)
      return
    }
    changeToastDetails(STR_SUCCESS, "Incentive Added!")
    changeToastVisibility(true)
  }

  const updateIncentive = async () => {
    const incentiveYearMonth1 = incentiveYearMonth.replace(/-/g, "")
    setIncentiveLoading(true)
    await fetchUpdatedInstructorIncentiveDetails(auth, id as string, parseInt(incentiveAmount), incentiveYearMonth1)
    await fetchInstructorIncentiveDetails(auth, id as string)
    setIncentiveLoading(false)
  }

  const fetchCreatedModuleClass = async (instructor_id: string) => {
    if (createModuleName == "other") {
      setCreateModuleName(otherModules)
    }
    const batches: any[] = []
    batches.push(createModuleBatch)
    const createModuleClass: CreateModuleClass = {
      auth,
      instructor_id: instructor_id,
      module: createModuleName,
      batch: batches,
      start_date: createModuleStartDate as string,
      end_date: createModuleEndDate as string,
      status: createModuleStatus,
      amount: createModuleAmount,
    }
    const response = await createModuleClassesUseCase.invoke(createModuleClass)
    if (!response?.success) {
      changeToastDetails(STR_FAILURE, genError(response, "Error occurred!"))
      changeToastVisibility(true)
      return
    }
    changeToastDetails(STR_SUCCESS, "Module Added!")
    changeToastVisibility(true)
  }

  function handleAmount(e: any) {
    if (e.target.value) setCompensationAmount(e.target.value)
    else setCompensationAmount("")
  }

  function handleIncentiveAmount(e: any) {
    if (e.target.value) setIncentiveAmount(e.target.value as string)
    else setIncentiveAmount("")
  }

  function handleYearMonth(e: any) {
    if (e.target.value) setYearMonth(e.target.value as string)
    else setYearMonth("")
  }

  function handleIncentiveYearMonth(e: any) {
    if (e.target.value) setIncentiveYearMonth(e.target.value as string)
    else setIncentiveYearMonth("")
  }

  async function addModuleSession() {
    setModuleloader(true)
    await fetchCreatedModuleClass(id as string)
    await fetchModuleClasses(auth, id as string)
    setModuleloader(false)
  }

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen)
  }

  const TableHeaders: string[] = ["Serial Number", "Compensation", "Month And Year", "Edit", "Delete"]
  const IncentiveTableHeaders: string[] = ["Serial Number", "Incentive", "Month And Year", "Delete"]
  const ModuleClassHeaders: string[] = [
    "serial Number",
    "Module",
    "Batch",
    "Start Date",
    "End Date",
    "Amount",
    "Status",
    "Edit",
    "Delete",
  ]
  const MonthName: string[] = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]
  const [name, setName] = React.useState<string>("")
  const ModuleStatusArr: string[] = ["InProgress", "Completed"]
  const MonthTableHead: string[] = [
    "Month",
    "Total Sessions",
    "Average Rating",
    "Amount",
    "View All Sessions",
    "View Invoice",
  ]
  React.useEffect(() => {
    if ("role" in auth) {
      fetchInstructorDetails(auth, id as string)
      fetchInstructorIncentiveDetails(auth, id as string)
      fetchModuleClasses(auth, id as string)
      getAllModules()
      getBatches()
      getInstructorsList()
      fetchInstructorAverageRating(auth, id as string)
      setName(id as string)
    }
  }, [auth, id as string, reloadData])

  function handleInstructorSelect(e: any) {
    navigate(`/admin/generate-invoice/${e.target.value}`)
  }

  return (
    <DashboardLayout>
      <div className="mx-4 my-4 flex flex-col gap-y-4">
        <div className="">
          <select className="h-9 w-[209px]" onChange={handleInstructorSelect} value={name}>
            <option value="">Instructor</option>
            {instructorList?.map((instructor: any, i: number) => (
              <option value={instructor?.email} key={i}>
                {instructor?.name}
              </option>
            ))}
          </select>
        </div>
        <div className="mx-2rem my-3rem flex justify-between px-6 py-4 text-new-neutral-dark shadow">
          <div className="flex items-end gap-x-6">
            <div className="space-y-1">
              <h4 className="mb-1">{instructorDetails.name}</h4>
              <p className="">
                {instructorDetails.current_company} | {instructorDetails.experience} years
              </p>
              <p className="">
                {instructorDetails.email} | +91 {instructorDetails.phone}
              </p>
            </div>
          </div>
          <div className="flex gap-x-12">
            <div className="">
              <p className="">{instructorDetails.status}</p>
            </div>
            <div className="flex flex-col gap-y-5">
              {!isEmpty(instructorAverageRating) ? (
                instructorAverageRating.average_rating != 0 ? (
                  <div className="">
                    {" "}
                    <h3 className="text-new-success">{instructorAverageRating.average_rating}/5</h3>
                    <p className="">Rating</p>
                  </div>
                ) : (
                  <div className="">Not Rated</div>
                )
              ) : (
                <Spinner small />
              )}
            </div>
          </div>
        </div>

        <Disclosure label="General Information">
          <div className="flex gap-x-6">
            <div className="flex-col gap-y-2">
              <div className="flex-col gap-y-2">
                <h4 className="">Instructor Name</h4>
                <div
                  className="m4 box-border flex h-[33px]  
                w-[319px] items-center justify-center border-4 p-4"
                >
                  <p className="">{instructorDetails.name}</p>
                </div>
              </div>
              <div className="flex-col gap-y-2">
                <h4 className="">Phone Number</h4>
                <div
                  className="m4 box-border flex h-[33px]  
                w-[319px] items-center justify-center border-4 p-4"
                >
                  <p className="">{instructorDetails.phone}</p>
                </div>
              </div>
            </div>
            <div className="flex-col gap-y-2">
              <div className="flex-col gap-y-2">
                <h4 className="">Company</h4>
                <div
                  className="m4 box-border flex h-[33px]  
                w-[319px] items-center justify-center border-4 p-4"
                >
                  <p className="">{instructorDetails.current_company}</p>
                </div>
              </div>
              <div className="flex-col gap-y-2">
                <h4 className="">Years Of Experience</h4>
                <div
                  className="m4 box-border flex h-[33px]  
                w-[319px] items-center justify-center border-4 p-4"
                >
                  <p className="">{instructorDetails.experience}</p>
                </div>
              </div>
            </div>
            <div className="flex-col gap-y-2">
              <div className="flex-col gap-y-2">
                <h4 className="">Email</h4>
                <div
                  className="m4 box-border flex h-[33px]  
                w-[319px] items-center justify-center border-4 p-4"
                >
                  <p className="">{instructorDetails.email}</p>
                </div>
              </div>
              <div className="flex-col gap-y-2">
                <h4 className="">LinkedIn ID</h4>
                <div
                  className="m4 box-border flex h-[33px]  
                w-[319px] items-center justify-center border-4 p-4"
                >
                  <p className="">
                    <a href={instructorDetails.linkedin_profile} className="">
                      LinkedIn Profile
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Disclosure>
        <Disclosure label="Instructor Compensation Details">
          <div className="flex flex-col gap-y-4">
            <div className="flex gap-x-6">
              <Input
                id="Amount"
                type="Amount"
                placeholder="Compensation Amount Per Hour"
                value={compensationAmount}
                onChange={handleAmount}
              />
              <Input
                id="yearMonth"
                type="text"
                placeholder="Enter Starting YearMonth"
                value={yearMonth}
                onBlur={(e) => (e.target.type = "text")}
                onFocus={(e) => (e.target.type = "month")}
                onChange={handleYearMonth}
              />
              <Button loading={Loading} onClick={updateCompensation}>
                Update Compensation
              </Button>
            </div>
            <CompensationTable
              changeToastDetails={changeToastDetails}
              changeToastVisibility={changeToastVisibility}
              reloadData={reloadData}
              setReloadData={setReloadData}
              tableHeader={TableHeaders}
              Compensation={instructorDetails.compensation}
            />
          </div>
        </Disclosure>
        <Disclosure label="Instructor Incentive Details">
          <div className="flex flex-col gap-y-4">
            <div className="flex gap-x-6">
              <Input
                id="Amount"
                type="number"
                max="100"
                min="0"
                placeholder="Incentive Percent"
                value={incentiveAmount}
                onChange={handleIncentiveAmount}
              />
              <Input
                id="yearMonth"
                type="text"
                placeholder="Enter Starting YearMonth"
                onBlur={(e) => (e.target.type = "text")}
                onFocus={(e) => (e.target.type = "month")}
                value={incentiveYearMonth}
                onChange={handleIncentiveYearMonth}
              />
              <Button loading={incentiveLoading} onClick={updateIncentive}>
                Update Incentive
              </Button>
            </div>
            <IncentiveTable
              reloadData={reloadData}
              setReloadData={setReloadData}
              IncentiveTableHeaders={IncentiveTableHeaders}
              incentive={instructorIncentiveDetails}
              changeToastDetails={changeToastDetails}
              changeToastVisibility={changeToastVisibility}
            />
          </div>
        </Disclosure>
        <Disclosure label="Module Compensation">
          <div className="flex flex-col gap-y-4">
            <div className="flex gap-x-6">
              <StandaredSelect
                options={moduleId}
                placeholder="Modules"
                onChange={handleCreateModuleName}
                value={createModuleName}
              />
              {createModuleName == "other" && (
                <Input type="text" value={otherModules} onChange={handleOtherModules} placeholder="Module Name" />
              )}
              <Input
                id="Amount"
                type="text"
                placeholder="Amount"
                value={createModuleAmount}
                onChange={handleCreateAmount}
                onBlur={(e) => (e.target.type = "text")}
                onFocus={(e) => (e.target.type = "number")}
              />
              <Input
                id="startMonth"
                type="text"
                placeholder="Start MM/YYYY"
                value={createModuleStartDate}
                onChange={handleCreateStartDate}
                onBlur={(e) => (e.target.type = "text")}
                onFocus={(e) => (e.target.type = "month")}
              />
              <StandaredSelect
                options={getBatchList}
                placeholder="Batch"
                onChange={handleCreateBatch}
                value={createModuleBatch}
              />
              <StandaredSelect
                options={ModuleStatusArr}
                placeholder="Status"
                onChange={handleCreateStatus}
                value={createModuleStatus}
              />
              <Input
                id="endMonth"
                type="text"
                placeholder="End MM/YYYY"
                value={createModuleEndDate}
                onChange={handleCreateEndDate}
                onBlur={(e) => (e.target.type = "text")}
                onFocus={(e) => (e.target.type = "month")}
              />
              <Button loading={moduleLoader} onClick={addModuleSession}>
                Add Module
              </Button>
            </div>
            <ModuleClassTable
              reloadData={reloadData}
              setReloadData={setReloadData}
              changeToastDetails={changeToastDetails}
              changeToastVisibility={changeToastVisibility}
              ModuleClassHeaders={ModuleClassHeaders}
              ModulesSessions={getModuleClasses}
            />
          </div>
        </Disclosure>
        <MonthlySessionTable
          reloadData={reloadData}
          setReloadData={setReloadData}
          TableHeader={MonthTableHead}
          MonthName={MonthName}
          mentorName={instructorDetails.name}
          mentorEmail={instructorDetails.email}
          data={instructorDetails}
          invoiceDetails={{
            address: instructorDetails?.address || "your address",
            beneficiary_name: instructorDetails?.beneficiary_name || "Your Beneficiary Name",
            IFSC: instructorDetails?.IFSC || "Your IFSC Code",
            account_number: instructorDetails?.account_number || "Your Account Number",
          }}
        />
      </div>
      <Toast data={toast} onClick={() => changeToastVisibility(false)} />
    </DashboardLayout>
  )
}
