import LoginView from "presentation/Student/Login/LoginView"
import CourseView from "presentation/Student/Course/CourseView"
import MentorView from "presentation/Student/Mentor/MentorView"
import LeaderboardView from "presentation/Student/Leaderboard/LeaderboardView"
import CourseSessionView from "presentation/Student/Course/CourseSessionView"
import ScheduleView from "presentation/Student/Schedule/ScheduleView"
import ProgressManagerView from "presentation/Student/ProgressManager/ProgressManagerView"
import MentorshipView from "presentation/Admin/Mentorship/MentorshipView"
import MentorshipEditView from "presentation/Admin/Mentorship/MentorshipEditView"
import MentorshipStudentEditView from "presentation/Admin/Mentorship/MentorshipStudentEditView"
import HomeView from "presentation/Student/Home/NewView"
import AdminHomeView from "presentation/Admin/Home/HomeView"
import MentorHomeView from "presentation/Mentor/Home/HomeView"
import UserSignupView from "presentation/Admin/UserSignup/UserSignupView"
import CreateClassView from "presentation/Admin/CreateClass/CreateClassView"
import ScheduledClassesView from "presentation/Admin/ViewScheduledClasses/ScheduledClassesView"
import StudentDashboardView from "presentation/Admin/StudentDashboard/StudentDashboardView"
import StudentProfileView from "presentation/Admin/StudentDashboard/StudentProfileView"
import MentorDashboardView from "presentation/Admin/MentorDashboard/MentorDashboardView"
import MentorProfilePageView from "presentation/Admin/MentorDashboard/MentorProfilePageView"
import UpdateBatchView from "presentation/Admin/UpdateBatch/UpdateBatchView"
import QuestionsView from "presentation/Admin/Questions/View"
import QuestionsChamberView from "presentation/Admin/QuestionChamber/View"
import SubCaseQuestionView from "presentation/Admin/QuestionChamber/Questions/SubCaseQuestionView"
import HomeAssessmentView from "presentation/Student/Home/Assessment/View"
import PlacementView from "presentation/Student/Placement/PlacementView"
import PlacementViewAdmin from "presentation/Admin/Placement/PlacementView"
import EditorView from "presentation/Student/Editor/EditorView"
import MCQView from "presentation/Student/Assignments/Mcq/View"
import CaseStudyView from "presentation/Student/Assignments/CaseStudy/View"
import SubjectiveView from "presentation/Student/Assignments/Subjective/View"
import JobDashboardView from "presentation/Admin/JobsDashboard/JobDashboardView"
import CreateJob from "presentation/Admin/JobsDashboard/CreateJobView"
import EligibleCandidateView from "presentation/Admin/JobsDashboard/EligibleCandidateView"
import PaymentMethodsView from "presentation/Student/Payment/PaymentMethodsView"
import AssessmentView from "presentation/Student/Assessment/ContestView"
import NotFound from "core/components/404"
import TestDashboardView from "presentation/Admin/TestDashboard/TestDashboardView"
import LiveClassView from "presentation/Mentor/LiveClass/LiveClassView"
import ProfileEditView from "presentation/Student/Profile/ProfileEditView"
import MyMenteesView from "presentation/Mentor/MyMentees/MyMenteesView"
import MenteeDetailsPage from "presentation/Mentor/MyMentees/MenteeDetailsPageView"
import ResumeView from "presentation/Student/Profile/ResumeView"
import ResumeCreateView from "presentation/Student/Profile/ResumeCreateView"
import StoreView from "presentation/Student/Store/StoreView"
import RedeemCoinsView from "presentation/Student/RedeemCoins/StoreView"
import AdminRedeemCoinsView from "presentation/Admin/RedeemCoins/StoreView"
import AdminStoreView from "presentation/Admin/Store/StoreView"
import HomeVideoSection from "presentation/Student/Home/components/HomeVideoSection"
import OrderHistory from "presentation/Student/Store/components/OrderHistory"
import OrderHistoryRedeemCoins from "presentation/Student/RedeemCoins/components/OrderHistory"
import ContestView from "presentation/User/Contest/View"
import ContestStart from "presentation/User/Contest/Start/View"
import ContestEditorView from "presentation/User/Contest/Editor/EditorView"
import SheetsView from "presentation/Admin/Sheets/SheetsView"
import AdminMcqsView from "presentation/Admin/Mcqs/McqsView"
import AdminContestView from "presentation/Admin/Contest/ContestView"
import FeedbackTable from "presentation/Admin/MonthlyFeedback/FeedbackTable"
import AdminMcqEditView from "presentation/Admin/Mcqs/McqEditView"
import AdminContestEditView from "presentation/Admin/Contest/ContestEditView"
import AdminContestSubmissionView from "presentation/Admin/Contest/ContestSubmissionView"
import MastersProgramView from "presentation/Student/MastersProgram/MastersProgramView"
import GetAllClassesView from "presentation/Admin/AllClasses/GetAllClassesView"
import DetailedRatingView from "presentation/Admin/AllClasses/DetailedRatingView"
import GenerateInvoiceView from "presentation/Admin/Invoice/GenerateInvoiceView"
import InstructorView from "presentation/Admin/Invoice/InstructorView"
import MonthSessionsView from "presentation/Admin/Invoice/MonthSessionsView"
import UpdateWalkThroughView from "presentation/Admin/UpdateWalkThrough/UpdateWalkThroughView"
import AllMentorSessionView from "presentation/Mentor/AllSession/AllSessionView"
import DoubtSupportView from "presentation/Student/DoubtSupport/DoubtSupportView"
import MonthlySessionsView from "presentation/Mentor/MonthlySessions/MonthlySessionsView"
import AvailabilityView from "presentation/Mentor/Availability/AvailabilityView"
import GoalSettingAvailabilityView from "presentation/Mentor/Availability/GoalSettingAvailabilityView"
import MentorsView from "presentation/Admin/MentorSessions/MentorsView"
import MentorSessionsView from "presentation/Admin/MentorSessions/MentorSessionsView"
import GenerateMentorInvoiceView from "presentation/Admin/MentorInvoicing/GenerateMentorInvoiceView"
import AdminMonthlySessionsView from "presentation/Admin/MentorInvoicing/MonthlySessionsView"
import MentorDetailsView from "presentation/Admin/MentorInvoicing/MentorDetailsView"
import InvoicingMainPage from "presentation/Admin/Invoicing/InvoicingMainPageView"
import CreateBatchView from "presentation/Admin/CreateBatch/CreateBatchView"
import CreateOnboardingManagerView from "presentation/Admin/CreateOnboardingManger/CreateOnboardingManagerView"
import QuestionView from "presentation/Admin/Questions/Question/View"
import ProgressRoadmapView from "presentation/Admin/StudentDashboard/ProgressRoadmap/ProgressRoadmapView"
import AdminContestDuplicateView from "presentation/Admin/Contest/ContestDuplicateView"
import PaymentOptionsView from "presentation/Student/PaymentOptions/PaymentOptionsView"
import PaymentBreakdownView from "presentation/Admin/StudentDashboard/BreakDowns/Razorpay/PaymentBreakdownView"
import DocumentationView from "presentation/Admin/Documentation/DocumentationView"
import DocumentationFileAddView from "presentation/Admin/Documentation/DocumentationFileAddView"
import CreateProjectSectionView from "presentation/Admin/ProjectSection/CreateProjectsSectionView"
import CreateNewProjectView from "presentation/Admin/ProjectSection/CreateNewProjectView"
import EditProjectView from "presentation/Admin/ProjectSection/EditProjectView"
import EditBatchView from "presentation/Admin/EditBatch/EditBatchView"
import EndBatchView from "presentation/Admin/EndBatch/EndBatchView"
import CoinsTracker from "presentation/Admin/CoinsTracker/CoinsTrackerView"
import TransactionView from "presentation/Admin/CoinsTracker/TransactionView"
import AddPlacementForm from "presentation/Admin/Placement/AddPlacementForm"
import CCAvenue from "presentation/Admin/StudentDashboard/BreakDowns/CCAvenue/CCAvenueBreakdownView"
import ConfirmOrder from "presentation/Student/Store/components/ConfirmOrder"
import PaymentInvoiceView from "presentation/Admin/PaymentInvoice/PaymentInvoiceView"
import BatchAssessmentView from "presentation/Admin/AllClasses/BatchAssignment/BatchAssessmentView"
import SubmissionView from "presentation/Admin/AllClasses/SubmissionView/SubmissionView"
import ComponentView from "presentation/ComponentView"
import BatchProgressRoadmapView from "presentation/Admin/BatchDashboard/BatchProgressRoadmapView"
import ContestProctoringView from "presentation/Admin/ContestProctoring/ContestProctoringView"
import ContestApproveView from "presentation/Admin/ContestApprove/ContestApproveView"
import AdminStudentSubmissionView from "presentation/Admin/Contest/SubmissionView/SubmissionView"
import ReferEarnView from "presentation/Student/ReferandEarn/ReferEarnView"
import ReferLinkView from "presentation/Student/ReferandEarn/ReferLinkView"
import ReferandEarnView from "presentation/Admin/ReferandEarn/ReferandEarnView"
import OnboardingView from "presentation/Student/Onboarding/OnboardingView"
import CompanyView from "presentation/Admin/Company/CompanyView"
import ResetPasswordView from "presentation/Admin/StudentDashboard/ResetPassword/View"
import CommunityAnnouncementsView from "presentation/Admin/CommunityAnnouncements/CommunityAnnouncementsView"
import InvoicingView from "presentation/Mentor/Invoicing/InvoicingView"
import InvoiceSessionDataView from "presentation/Mentor/Invoicing/InvoicingSession/InvoiceSessionDataView"
import NewMentorView from "presentation/Student/Mentor/NewMentorView"

export interface RouteConfig {
  path: string
  component: () => JSX.Element
  isProtected?: boolean
}

const routes: RouteConfig[] = [
  { path: "/login", component: LoginView, isProtected: false },

  // STUDENT
  { path: "/home", component: HomeView },
  { path: "/home/contest", component: HomeAssessmentView },
  {
    path: "/home/meet_and_greet/:id",
    component: () => HomeVideoSection({ type: "meet_and_greet" }),
  },
  {
    path: "/home/prerequisite/:id",
    component: () => HomeVideoSection({ type: "prerequisite" }),
  },
  { path: "/onboarding", component: OnboardingView },
  { path: "/placement", component: PlacementView },
  { path: "/placement/edit", component: ProfileEditView },
  { path: "/placement/edit/resume", component: ResumeView },
  { path: "/placement/edit/resume/create", component: ResumeCreateView },
  { path: "/course", component: CourseView },
  { path: "/course/:session_id", component: CourseSessionView },
  { path: "/payment", component: PaymentMethodsView },
  { path: "/doubt-support", component: DoubtSupportView },
  { path: "/mentor", component: NewMentorView },
  { path: "/schedule", component: ScheduleView },
  { path: "/progress-meeting", component: ProgressManagerView },
  { path: "/weekly-test-series", component: AssessmentView },
  { path: "/leaderboard", component: LeaderboardView },
  { path: "/store", component: StoreView },
  { path: "/store/:item_id", component: ConfirmOrder },
  { path: "/store/history", component: OrderHistory },
  { path: "/redeem-coins", component: RedeemCoinsView },
  { path: "/redeem-coins/history", component: OrderHistoryRedeemCoins },
  { path: "/refer", component: ReferEarnView },
  { path: "/referlink", component: ReferLinkView, isProtected: false },
  { path: "/referlink/:email", component: ReferLinkView, isProtected: false },
  { path: "/transaction", component: PaymentOptionsView },
  { path: "/masters-program", component: MastersProgramView },
  { path: "/contests", component: ContestView },
  { path: "/contests/:contest_id", component: ContestStart },
  { path: "/contests/:contest_id/editor", component: ContestEditorView },
  { path: "/editor/:id", component: EditorView },
  { path: "/mcqs/:id", component: MCQView },
  { path: "/subjective/:id", component: SubjectiveView },
  { path: "/casestudy/:id", component: CaseStudyView },

  // ADMIN
  { path: "/admin/home", component: AdminHomeView },
  { path: "/admin/end-batch", component: EndBatchView },
  { path: "/admin/create-class", component: CreateClassView },
  { path: "/admin/project-section", component: CreateProjectSectionView },
  { path: "/admin/project-section/create-new-project", component: CreateNewProjectView },
  { path: "/admin/project-section/edit-project/:projectId", component: EditProjectView },
  { path: "/admin/create-batch", component: CreateBatchView },
  { path: "/admin/create-onboarding-manager", component: CreateOnboardingManagerView },
  { path: "/admin/batch-progress-roadmap", component: BatchProgressRoadmapView },
  { path: "/admin/edit-batch", component: EditBatchView },
  { path: "/admin/user-signup", component: UserSignupView },
  { path: "/admin/referandearn", component: ReferandEarnView },
  { path: "/admin/invoice", component: PaymentInvoiceView },
  { path: "/admin/community-announcements", component: CommunityAnnouncementsView },
  { path: "/admin/placement", component: PlacementViewAdmin },
  { path: "/admin/placement/edit/:id", component: AddPlacementForm },
  { path: "/admin/placement/add", component: AddPlacementForm },
  { path: "/admin/students", component: StudentDashboardView },
  { path: "/admin/students/:id", component: StudentProfileView },
  { path: "/admin/students/:id/payment-breakdown", component: PaymentBreakdownView },
  { path: "/admin/students/:id/ccavenue-payment-breakdown", component: CCAvenue },
  { path: "/admin/students/:id/progress-roadmap", component: ProgressRoadmapView },
  { path: "/admin/students/:id/reset-password", component: ResetPasswordView },
  { path: "/admin/mentor-dashboard", component: MentorDashboardView },
  { path: "/admin/mentor-profile/edit/:id", component: MentorProfilePageView },
  { path: "/admin/update-batch", component: UpdateBatchView },
  { path: "/admin/questions", component: QuestionsView },
  { path: "/admin/questions/:id", component: QuestionView },
  { path: "/admin/question-chamber", component: QuestionsChamberView },
  { path: "/admin/question-chamber/:id", component: SubCaseQuestionView },
  { path: "/admin/question-chamber/:op/:id", component: SubCaseQuestionView },
  { path: "/admin/scheduled-classes", component: ScheduledClassesView },
  { path: "/admin/job-dashboard", component: JobDashboardView },
  { path: "/admin/job-create-job", component: CreateJob },
  { path: "/admin/job-eligible-candidates/:id", component: EligibleCandidateView },
  { path: "/admin/test-dashboard", component: TestDashboardView },
  { path: "/admin/mentorship", component: MentorshipView },
  { path: "/admin/mentorship/:id", component: MentorshipStudentEditView },
  { path: "/admin/mentorship/:id/:type", component: MentorshipEditView },
  { path: "/admin/redeem-coins", component: AdminRedeemCoinsView },
  { path: "/admin/coins-tracker", component: CoinsTracker },
  { path: "/admin/coins-tracker/transaction-history/:email", component: TransactionView },
  { path: "/admin/proctoring", component: ContestProctoringView },
  { path: "/admin/store", component: AdminStoreView },
  { path: "/admin/sheets", component: SheetsView },
  { path: "/admin/contests", component: AdminContestView },
  { path: "/admin/contests/:id/view", component: AdminContestSubmissionView },
  { path: "/admin/contests/:id/view/:id", component: AdminStudentSubmissionView },
  { path: "/admin/contests/:id", component: AdminContestEditView },
  { path: "/admin/contests/:id/duplicate", component: AdminContestDuplicateView },
  { path: "/admin/approval-requests", component: ContestApproveView },
  { path: "/admin/mcqs", component: AdminMcqsView },
  { path: "/admin/mcqs/:id", component: AdminMcqEditView },
  { path: "/admin/documentation_files", component: DocumentationView },
  { path: "/admin/documentation_files/:id", component: DocumentationFileAddView },
  { path: "/admin/all-feedback", component: FeedbackTable },
  { path: "/admin/all-classes", component: GetAllClassesView },
  { path: "/admin/all-classes/:id", component: DetailedRatingView },
  { path: "/admin/all-classes/:id/submission", component: BatchAssessmentView },
  { path: "/admin/all-classes/:id/submission/:email_id", component: SubmissionView },
  { path: "/admin/generate-invoice", component: GenerateInvoiceView },
  { path: "/admin/generate-invoice/:id", component: InstructorView },
  { path: "/admin/generate-invoice/:id/:yearMonth", component: MonthSessionsView },
  { path: "/admin/update-walk-through", component: UpdateWalkThroughView },
  { path: "/admin/invoicing", component: InvoicingMainPage },
  { path: "/admin/mentor-sessions", component: MentorsView },
  { path: "/admin/mentor-sessions/:id", component: MentorSessionsView },
  { path: "/admin/mentor-invoice", component: GenerateMentorInvoiceView },
  { path: "/admin/mentor-invoice/:id", component: MentorDetailsView },
  { path: "/admin/mentor-invoice/:id/:yearMonth", component: AdminMonthlySessionsView },
  { path: "/admin/components", component: ComponentView },
  { path: "/admin/company-tags", component: CompanyView },

  // MENTOR
  { path: "/mentor/home", component: MentorHomeView },
  { path: "/mentor/sessions", component: AllMentorSessionView },
  { path: "/mentor/live-class", component: LiveClassView },
  { path: "/mentor/mentees", component: MyMenteesView },
  { path: "/mentor/mentees/:id", component: MenteeDetailsPage },
  { path: "/mentor/monthly-sessions", component: MonthlySessionsView },
  { path: "/mentor/availability", component: AvailabilityView },
  { path: "/mentor/goal-setting-availability", component: GoalSettingAvailabilityView },
  { path: "/mentor/invoicing/:page", component: InvoicingView },
  { path: "/mentor/invoicing-sessions/:role/:month", component: () => InvoiceSessionDataView({}) },

  // 404
  { path: "*", component: NotFound },
]

export default routes
