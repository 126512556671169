import React from "react"
import Button from "core/components/new/Button"
import EditScheduledClassModel from "./EditScheduledClassModel"
import UpdateClassForm from "./UpdateClassForm"
import DuplicateClassForm from "./DuplicateClassForm"
import DuplicateClassFormViewModel from "./DuplicateClassFormViewModel"
import Modal from "core/components/Modal"
import { STR_SUCCESS } from "core/constants/strings"
import { format } from "date-fns"
import { isEmpty } from "core/utils/misc"

export default function ScheduledClassCard(props: any) {
  const { setLevel } = DuplicateClassFormViewModel()
  const [isOpen, setIsOpen] = React.useState(false)
  const [isModalOpen, setModalOpen] = React.useState(false)

  const openModal = () => setModalOpen(true)
  const closeModal = () => setModalOpen(false)

  const handleClick = () => {
    setIsOpen(true)
  }
  const [isDuplicateOpen, setIsDuplicateOpen] = React.useState(false)
  const [isDeleteOpen, setIsDeleteOpen] = React.useState(false)
  const [deleteLoader, setDeleteLoader] = React.useState(false)
  const handleDuplicateClick = () => {
    setLevel(props.level)
    setIsDuplicateOpen(true)
  }
  const handleDuplicateClose = () => {
    setIsDuplicateOpen(false)
  }
  const handleClose = () => {
    setIsOpen(false)
  }
  const handleConfirmDeleteModal = () => {
    setIsDeleteOpen(!isDeleteOpen)
  }
  async function handleDeleteSession() {
    setDeleteLoader(true)
    props.changeToastVisibility(true)
    await props.deleteScheduleClass(props.session_id)
    await props.setUpcomingClassBool(!props.upcomingClassBool)
    props.changeToastDetails(STR_SUCCESS, "Session Deleted")
    setDeleteLoader(false)

    setIsDeleteOpen(!isDeleteOpen)
  }

  return (
    <>
      <div className="flex relative px-6 py-4 text-new-neutral-dark shadow rounded-[4px]">
        <div className="flex w-full">
          <div className="space-y-2 w-full">
            <div>
              <h3 className="mb-1">{props.oldClassData.chapter_name}</h3>
            </div>
            <div className="flex pt-1 flex-wrap">
              {!isEmpty(props.oldClassData?.batches) ? (
                props.oldClassData.batches.map((item: any, index: number) => (
                  <p key={index} className={index === 0 ? "" : "flex flex-wrap"}>
                    {index === 0 ? item : ` | ${item}`}
                  </p>
                ))
              ) : (
                <p className="italic">No Batch Found</p>
              )}
            </div>
            <div className="flex justify-between">
              {!isEmpty(props.oldClassData?.instructor) ? (
                <p className="flex flex-col-reverse font-bold">
                  Instructor:{" "}
                  {props.oldClassData.instructor_name !== ""
                    ? props.oldClassData.instructor_name
                    : "No Instructor Found"}
                </p>
              ) : (
                <p className="italic">No Instructor Found</p>
              )}
              <div className="flex justify-between space-x-2 items-baseline">
                <div className="my-auto flex flex-col items-end gap-y-5">
                  <Button outlined className="w-fit" onClick={handleConfirmDeleteModal}>
                    {props.cancelLoading ? "Cancelling" : "Delete Session"}
                  </Button>
                </div>
                <div className="my-auto flex flex-col items-end gap-y-5">
                  <Button outlined className="w-fit" onClick={handleClick}>
                    Edit class
                  </Button>
                </div>
                <div className="my-auto flex flex-col items-end gap-y-5">
                  <Button outlined className="w-fit" onClick={handleDuplicateClick}>
                    Duplicate class
                  </Button>
                </div>
              </div>
            </div>
            <div className="flex bg-gray-100 gap-4 font-semibold p-4 w-full rounded-[4px] mt-4">
              <p>{format(parseInt(props.oldClassData.session_timestamp) * 1000, "do MMMM, yyyy | hh:mm a")}</p>
              <p className="">Class Link: {props.oldClassData.class_link}</p>
            </div>
          </div>
        </div>
      </div>
      <EditScheduledClassModel isOpen={isOpen} onClose={handleClose}>
        <UpdateClassForm
          upcomingClassBool={props.upcomingClassBool}
          setUpcomingClassBool={props.setUpcomingClassBool}
          batch={props.getBatchList}
          instructor={props.instructorList}
          moduleId={props.getModulesList}
          track={props.getTracksList}
          session_id={props.oldClassData.session_id}
          currentSelectBatch={props.oldClassData.batches}
          chapterNames={props.oldClassData.chapter_name}
          classLink={props.oldClassData.class_link}
          selectInstructor={props.oldClassData.instructor}
          selectModuleId={props.oldClassData.module_id}
          notes_link={props.oldClassData.notes_link}
          selectAssigmentsQuestions={props.oldClassData.problems.assignments}
          selectHomeworkQuestions={props.oldClassData.problems.homework}
          selectPracticalQuestions={props.oldClassData.problems.practice}
          selectWarmupQuestions={props.oldClassData.problems.warmup}
          session_timestamp={props.oldClassData.session_timestamp}
          session_duration={props.oldClassData.session_duration}
          selectTrack={props.oldClassData.track}
          videoId={props.oldClassData.video_id}
          videoSecret={props.oldClassData.video_secret}
          notesLink={props.oldClassData.notes_link}
          assignmentSolutionLink={props.oldClassData.assignment_solution_link}
          assignmentSolutionActivationTime={props.oldClassData.assignment_solution_activation_time}
          notesSummaryLink={props.oldClassData.notes_summary_link}
          assignmentCoins={props.oldClassData?.assignment_points}
          status={props.status}
        />
      </EditScheduledClassModel>
      <EditScheduledClassModel isOpen={isDuplicateOpen} onClose={handleDuplicateClose}>
        <DuplicateClassForm
          duplicateClassBool={props.duplicateClassBool}
          setDulplicateClassBool={props.setDulplicateClassBool}
          batch={props.getBatchList}
          instructor={props.instructorList}
          // moduleId={props.getModulesList}
          // track={props.getTracksList}
          session_duration={props.oldClassData.session_duration}
          session_id={props.oldClassData.session_id}
          currentSelectBatch={props.oldClassData.batches}
          chapterNames={props.oldClassData.chapter_name}
          classLink={props.oldClassData.class_link}
          selectInstructor={props.oldClassData.instructor}
          selectModuleId={props.oldClassData.module_id}
          notes_link={props.oldClassData.notes_link}
          selectAssigmentsQuestions={props.oldClassData.problems.assignments}
          selectHomeworkQuestions={props.oldClassData.problems.homework}
          selectPracticalQuestions={props.oldClassData.problems.practice}
          selectWarmupQuestions={props.oldClassData.problems.warmup}
          session_timestamp={props.oldClassData.session_timestamp}
          selectTrack={props.oldClassData.track}
          videoId={props.oldClassData.video_id}
          videoSecret={props.oldClassData.video_secret}
          notesLink={props.oldClassData.notes_link}
          notesSummaryLink={props.oldClassData.notes_summary_link}
          assignmentSolutionLink={props.oldClassData.assignment_solution_link}
          assignmentSolutionActivationTime={props.oldClassData.assignment_solution_activation_time}
          level={props.oldClassData.level}
          assignmentCoins={props.oldClassData?.assignment_points}
        />
      </EditScheduledClassModel>
      <Modal open={isDeleteOpen} onClose={handleConfirmDeleteModal}>
        <div className="flex flex-col gap-4">
          <div className="space-y-2">
            <h3 className="text-new-accent">Delete Session</h3>
            <p>Are you sure you want to delete session</p>
          </div>
          <div className="flex items-center gap-2 self-end">
            <Button outlined failure onClick={handleConfirmDeleteModal}>
              No
            </Button>
            <Button outlined onClick={handleDeleteSession} loading={deleteLoader}>
              Yes
            </Button>
          </div>
        </div>
      </Modal>
    </>
  )
}
