import axios from "axios"
import { useAuth } from "core/context/auth"
import React, { useEffect, useState } from "react"
import SessionCard from "../components/SessionCard"
import Button from "core/components/new/Button"
import Spinner from "core/components/Spinner"
import { useParams } from "react-router-dom"
import DashboardLayout from "core/layouts/DashboardLayout"

import SessionAPIDataSourceImpl from "data/API/Mentor/SessionAPIDataSourceImpl"
import GetAllSessions from "domain/useCase/Mentor/Session/GetAllSessions"
import { SessionRepositoryImpl } from "data/repository/Mentor/SessionRepositoryImpl"
import useLocalStorage from "core/hooks/useLocalStorage"
import GetInstructorSessions from "domain/useCase/Mentor/Session/GetInstructorSessions"
import GetMentorSession from "domain/useCase/Mentor/Session/GetMentorSession"
import getMentorGoalSettingSession from "domain/useCase/Mentor/Session/GetMentorGoalSettingSession"

function getFirstDateOfMonthTimestamp(month: string) {
  const monthIndex = new Date(`${month} 1, 2024`).getMonth()
  const date = new Date(2024, monthIndex, 1)
  const timestamp = Math.floor(date.getTime() / 1000)
  return timestamp
}

const monthNumbers: { [key: string]: string } = {
  january: "01",
  february: "02",
  march: "03",
  april: "04",
  may: "05",
  june: "06",
  july: "07",
  august: "08",
  september: "09",
  october: "10",
  november: "11",
  december: "12",
}

const InvoiceSessionDataView = ({ isInstructor }: any) => {
  const [sessions, setSessions] = useState([])
  const [filteredSessions, setFilteredSessions] = useState<any[]>([])
  const [currentPage, setCurrentPage] = useState(1)
  const [loading, setLoading] = useState(true)
  const [sessionsPerPage] = useState(5)
  const [filterType, setFilterType] = useState("total")
  const { auth } = useAuth()
  const [mentor, useMentor] = useLocalStorage("mentor", {} as any)
  console.log(mentor)
  const params = useParams()
  const { id } = useParams()
  const role = params.role as string
  const month = params.month as string
  const time_giving = getFirstDateOfMonthTimestamp(month)
  const [goalSettingSessions, setGoalSettingSessions] = useState([])
  const getAllSessionsUseCase = new GetAllSessions(new SessionRepositoryImpl(new SessionAPIDataSourceImpl()))
  const getInstructorSessionsUseCase = new GetInstructorSessions(
    new SessionRepositoryImpl(new SessionAPIDataSourceImpl())
  )
  const getMentorSessionsUseCase = new GetMentorSession(new SessionRepositoryImpl(new SessionAPIDataSourceImpl()))

  const getAllSessions = async () => {
    try {
      console.log("BEFORE CALLING GET ALL SESSIONS")
      const data =
        role === "instructor" ? await fetchInstructorSessions(time_giving) : await fetchMentorSession(time_giving)
      console.log("AFTER", data)
      console.log("these are sessions", data.data.data)

      const filteredData = data.data.data.filter((session: any) => !session.id.includes("msid1"))

      console.log("these are  new sessions", filteredData)

      setSessions(filteredData)
      setFilteredSessions(filteredData)
      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  const fetchInstructorSessions = async (month: number, batch: string = "", module: string = "") => {
    const response = await getInstructorSessionsUseCase.invoke(auth, month, batch, module)
    return response
  }

  const fetchMentorSession = async (month: number, batch: string = "", module: string = "") => {
    const response = await getMentorSessionsUseCase.invoke(auth, month, batch, module)
    return response
  }

  useEffect(() => {
    filterSessions(filterType)
  }, [filterType, sessions])

  const handlePageChange = (page: any) => {
    setCurrentPage(page)
  }

  const filterSessions = (type: string) => {
    let filtered: any = []
    // if(type = "All mentor sessions"){
    //   filtered = [...sessions, ...goalSettingSessions]
    // }

    if (type === "total" || type === "All") {
      filtered = sessions
    } else if (type === "taken") {
      filtered = sessions.filter(
        (session) =>
          (session as any).status === "Class Taken" ||
          (session as any).status === "Completed" ||
          (session as any).status === undefined
      )
    } else if (type === "substituted") {
      filtered = sessions.filter((session) => (session as any).status === "Substituted")
    } else if (type === "cancelled") {
      filtered = sessions.filter((session) => (session as any).status === "Cancelled Session")
    } else if (type === "Taken Elsewhere") {
      filtered = sessions.filter((session) => (session as any).status === "Taken Elsewhere")
    } else if (type === "cancelled_by_mentor") {
      filtered = sessions.filter((session) => (session as any).status === "Cancelled by Mentor")
    } else if (type === "cancelled_by_mentee") {
      filtered = sessions.filter((session) => (session as any).status === "Cancelled by Mentee")
    } else if (type === "Mentor Not Joined") {
      filtered = sessions.filter(
        (session) => (session as any).status === "Mentor Absent" || (session as any).status === "Mentor Not Joined"
      )
    } else if (type === "Mentee Not Joined") {
      filtered = sessions.filter(
        (session) => (session as any).status === "Mentee Absent" || (session as any).status === "Mentee Not Joined"
      )
    } else if (type === "Cancelled Post Time") {
      filtered = sessions.filter((session) => (session as any).status === "Cancelled Post Time")
    } else if (type === "Cancelled On Time") {
      filtered = sessions.filter((session) => (session as any).status === "Cancelled On Time")
    } else if (type === "Cancelled Last Min") {
      filtered = sessions.filter((session) => (session as any).status === "Cancelled Last Min")
    } else if (type === "goal_setting_sessions") {
      filtered = goalSettingSessions.filter((session) => (session as any).session_id === "msid1")
    } else if (type === "goal_setting_completed") {
      filtered = goalSettingSessions.filter((session) => (session as any).session_status === "Completed")
    }
    setFilteredSessions(filtered)
    setCurrentPage(1)
  }

  // Calculate session counts for each type
  const totalCount = sessions.length
  const takenCount = sessions.filter(
    (session) =>
      (session as any).status === "Class Taken" ||
      (session as any).status === undefined ||
      (session as any).status === "Completed" ||
      (session as any).status === "Session Taken"
  ).length
  const substitutedCount = sessions.filter((session) => (session as any).status === "Substituted").length
  const cancelledOnTime = sessions.filter((session) => (session as any).status === "Cancelled On Time").length
  const cancelledPostTime = sessions.filter((session) => (session as any).status === "Cancelled Post Time").length
  const cancelledLastMin = sessions.filter((session) => (session as any).status === "Cancelled Last Min").length
  const cancelledByMentorCount = sessions.filter((session) => (session as any).status === "Cancelled by Mentor").length
  const cancelledByMenteeCount = sessions.filter((session) => (session as any).status === "Cancelled by Mentee").length
  const takenElsewhere = sessions.filter((session) => (session as any).status === "Taken Elsewhere").length
  const menteeNotJoined = sessions.filter(
    (session) => (session as any).status === "Mentee Not Joined" || (session as any).status === "Mentor Absent"
  ).length
  const mentorNotJoined = sessions.filter(
    (session) => (session as any).status === "Mentor Not Joined" || (session as any).status === "Mentee Absent"
  ).length

  // Logic for displaying sessions
  const indexOfLastSession = currentPage * sessionsPerPage
  const indexOfFirstSession = indexOfLastSession - sessionsPerPage
  const currentSessions = filteredSessions.slice(indexOfFirstSession, indexOfLastSession)

  const totalPages = Math.ceil(filteredSessions.length / sessionsPerPage)

  const getMentorGoalSettingSessionUseCase = new getMentorGoalSettingSession(
    new SessionRepositoryImpl(new SessionAPIDataSourceImpl())
  )

  const fetchMentorGoalSettingSessions = async (mentor_id: string = "", month: number) => {
    const response = await getMentorGoalSettingSessionUseCase.invoke(auth, mentor_id, month)
    return response
  }
  const fetchGoalSettingSessions = async () => {
    try {
      const monthNumber = monthNumbers[month.toLocaleLowerCase()]
      const response = await fetchMentorGoalSettingSessions(mentor.mentor?.email, parseInt(monthNumber ?? "0", 10))
      console.log(response, "kimJong")

      const data = response.data
      setGoalSettingSessions(data)
      // const allSessions : any[] = [...sessions, ...data.data]
      // setSessions(allSessions);
    } catch (error) {
      console.log(error)
    }
  }

  const goalSettingCompleted = goalSettingSessions.filter(
    (session) => (session as any).session_status === "Completed"
  ).length

  useEffect(() => {
    getAllSessions()
    fetchGoalSettingSessions()
  }, [])
  return (
    <DashboardLayout>
      {loading ? (
        <div className="flex justify-center items-center mt-10 p-4">
          <Spinner />
        </div>
      ) : (
        <div>
          {role === "mentor" && (
            <div className="flex justify-center gap-5 mb-5 px-5 pt-6">
              <Button
                onClick={() => setFilterType("total")}
                className={`btn ${filterType === "total" ? "active " : "bg-gray-300"} flex items-center text-white font-bold rounded-[4px] p-1`}
              >
                All ({sessions.length})
              </Button>
              <Button
                onClick={() => setFilterType("taken")}
                className={`btn ${filterType === "taken" ? "active " : "bg-gray-300"} flex items-center text-white font-bold rounded-[4px] p-1`}
              >
                Taken ({takenCount})
              </Button>
              <Button
                onClick={() => setFilterType("cancelled_by_mentor")}
                className={`btn ${filterType === "cancelled_by_mentor" ? "active" : "bg-gray-300"} flex items-center rounded-[4px] p-1`}
              >
                Cancelled by Mentor ({cancelledByMentorCount})
              </Button>
              <Button
                onClick={() => setFilterType("cancelled_by_mentee")}
                className={`btn ${filterType === "cancelled_by_mentee" ? "active" : "bg-gray-300"} flex items-center rounded-[4px] p-1`}
              >
                Cancelled by Mentee ({cancelledByMenteeCount})
              </Button>
              <Button
                onClick={() => setFilterType("Taken Elsewhere")}
                className={`btn ${filterType === "Taken Elsewhere" ? "active" : "bg-gray-300"} flex items-center rounded-[4px] p-1`}
              >
                Taken Elsewhere ({takenElsewhere})
              </Button>
              <Button
                onClick={() => setFilterType("Mentor Not Joined")}
                className={`btn ${filterType === "Mentor Not Joined" ? "active" : "bg-gray-300"} flex items-center rounded-[4px] p-1`}
              >
                Mentor not Joined ({mentorNotJoined})
              </Button>
              <Button
                onClick={() => setFilterType("Mentee Not Joined")}
                className={`btn ${filterType === "Mentee Not Joined" ? "active" : "bg-gray-300"} flex items-center rounded-[4px] p-1`}
              >
                Mentee not Joined ({menteeNotJoined})
              </Button>
              <Button
                onClick={() => setFilterType("goal_setting_sessions")}
                className={`btn ${filterType === "goal_setting_sessions" ? "active" : "bg-gray-300"} flex items-center rounded-[4px] p-1`}
              >
                Goal Setting Sessions ({goalSettingSessions.length})
              </Button>
              <Button
                onClick={() => setFilterType("goal_setting_completed")}
                className={`btn ${filterType === "goal_setting_completed" ? "active" : "bg-gray-300"} flex items-center rounded-[4px] p-1`}
              >
                Completed Goal Setting Sessions ({goalSettingCompleted})
              </Button>
            </div>
          )}
          {role === "instructor" && (
            <div className="flex justify-center gap-5 mb-5 px-5 pt-6">
              <Button
                onClick={() => setFilterType("taken")}
                className={`btn ${filterType === "taken" ? "active" : "bg-gray-300"} flex items-center text-white font-bold rounded-[4px]`}
              >
                Taken ({takenCount})
              </Button>
              <Button
                onClick={() => setFilterType("substituted")}
                className={`btn ${filterType === "substituted" ? "active" : "bg-gray-300"} flex items-center rounded-[4px]`}
              >
                Substituted ({substitutedCount})
              </Button>
              <Button
                onClick={() => setFilterType("Cancelled On Time")}
                className={`btn ${filterType === "Cancelled On Time" ? "active" : "bg-gray-300"} flex items-center rounded-[4px]`}
              >
                Cancelled On Time({cancelledOnTime})
              </Button>
              <Button
                onClick={() => setFilterType("Cancelled Post Time")}
                className={`btn ${filterType === "Cancelled Post Time" ? "active" : "bg-gray-300"} flex items-center rounded-[4px] px-2`}
              >
                Cancelled Post Time({cancelledPostTime})
              </Button>
              <Button
                onClick={() => setFilterType("Cancelled Last Min")}
                className={`btn ${filterType === "Cancelled Last Min" ? "active" : "bg-gray-300"} flex items-center rounded-[4px] px-2`}
              >
                Cancelled Last Min({cancelledLastMin})
              </Button>
            </div>
          )}
          {filteredSessions.length === 0 ? (
            <div className="text-center mt-10 ">
              <p className="text-3xl">No data available</p>
            </div>
          ) : (
            <div className="p-6 pt-4">
              {currentSessions.map((session, index) => (
                <SessionCard key={index} session={session} isMentor={role === "mentor"} />
              ))}
              <div className="flex flex-row justify-center gap-10 items-center mt-10">
                <Button
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                  className="w-[6px] h-12 rounded-md"
                >
                  <span className="text-2xl "> {"<-"}</span>
                </Button>
                {[...Array(totalPages)].map((_, index) => (
                  <button
                    key={index}
                    onClick={() => handlePageChange(index + 1)}
                    className={`${index + 1 === currentPage ? "active text-green-400 font-800" : ""} flex flex-row justify-center`}
                  >
                    {index + 1}
                  </button>
                ))}
                <Button
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages}
                  className="w-[6px] h-12 rounded-md"
                >
                  <span className="text-2xl "> {"->"}</span>
                </Button>
              </div>
            </div>
          )}
        </div>
      )}
    </DashboardLayout>
  )
}

export default InvoiceSessionDataView
